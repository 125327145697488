.assignment-remark {
    width: 100%;
    resize: none;
    height: 100px;
    font-size: 15px;
    background: #f9f8a9;
    border: 2px solid #dddb54;
}

html {
    height: 100%;
}
body {
    position: relative;
    min-height: 100%;
    margin: 0;
    padding-bottom: 9rem;
    font-family: "Roboto", sans-serif;
}

.pointer {
    cursor: pointer;
}
.center {
    text-align: center;
}
.page-title-bar {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    padding: 5px;
}

footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #efefef;
    text-align: center;
}

.collapse-able {
    transition: 0.5s;
    background: white;
    color: black;
}
.collapse-able:hover {
    filter: brightness(80%);
}

#cssloaderForLoading {
    position: fixed;
    display: none;
    left: 0;
    top: 0;
    z-index: 9999999999;
    width: 100%;
    height: 100%;
    overflow: visible;
    background: rgba(0, 0, 0, 0.7);
}
#cssloaderForLoading .loading-txt {
    font-size: 25px;
    font-weight: 800;
    color: white;
    text-align: center;
}

/**
  CSS FOR MOBILE SIZE - MODAL BOX
  -Width to 100%
  -Height to Auto
**/
@media only screen and (max-width: 768px) {
    .modal-dialog {
        width: 100% !important;
    }
    .modal {
        padding-right: 18px !important;
    }
    .modal-body {
        height: auto !important;
        min-height: 0px !important;
    }
}

/*
    Design For Dashboard Button
*/
.fn-div {
    text-align: center;
    width: 150px;
    border-radius: 5px 5px 0px 0px;
    border-color: black;
}

.center {
    display: flex !important;
    justify-content: center !important;
}

@media screen and (max-width: 600px) {
    .fn-div {
        width: 100px;
    }

    .fn-icon {
        height: 80px !important;
        line-height: 110px !important;
    }
}

.fn-div a {
    text-decoration: none;
}

.fn-icon {
    border: 1px solid black;
    border-radius: 5px 5px 0px 0px;
    border-bottom: none;
    color: white;
    height: 126px;
    line-height: 160px;
}

.fn-text {
    border: 1px solid black;
    border-radius: 0px 0px 5px 5px;
    border-top: none;
    background: white;
    color: black;
    height: 25px;
    font-weight: 700;
    font-size: 14px;
}

.dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    border: 1px solid black;
}

.c-dementia {
    background: #F4FD84;
}

.c-living-alone {
    background: #918DFF;
}

.c-dementia-living-alone {
    background: #FF84E0;
}



.c-completed {
    background: #77ff85;
}

.c-pending {
    background: #b6fffe;
}

.c-normal {
    background: white;
}

.c-started {
    background: #ff9800;
}

.c-cancelled {
    background: #fed4e1;
}

.timepicker2 {
    background: white !important;
}

.datetimepicker-close-button-text {
    margin: 5px 5px;
    color: #0288d1;
    border-radius: 5px;
}

.hidden {
    display: none !important;
}

.margin5 {
    margin: 5px 0px;
}

.remark {
    resize: vertical;
    min-height: 100px;
}

.right {
    float: right;
}
.txt-right {
    text-align: right;
}

.tiny-italic-txt {
    font-size: 11.5px;
    font-style: italic;
}

.fit {
    white-space: nowrap;
    width: 1%;
}

.table-valign tbody tr td {
    vertical-align: middle;
}

/*
  FOR BOTTOM NAVIGATOR USE
*/

.bottom-navigator {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: #f5f5f5;

    border-top: 1px solid transparent;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    color: black;
}

.bottom-navigator .selectors,
.block {
    height: 100%;
    width: 100%;
}

.bottom-navigator .selectors button {
    border: 0;
    border-radius: 0;
    background-color: #f8f9fa !important;
    width: 25%;
    margin-left: 0;
}

.bottom-navigator .selectors button:active {
    border: 0;
}

.bottom-navigator .selectors button:focus {
    border: 0;
    outline: 0;
    box-shadow: 0 0 0 0px;
}

.bottom-navigator .active,
.bottom-navigator .selector-holder {
    display: flex;
    flex-direction: column;
}

.bottom-navigator .inactive {
    display: none;
}

.bottom-navigator .selector-holder span {
    font-size: 0.8rem;
}
/*
  END OF Bottom Navigator bar
*/

.divider {
    width: 100%;
    height: 1px;
    margin: 9px 1px;
    margin: -5px 0 5px;
    overflow: hidden;
    background-color: #e5e5e5;
    border-bottom: 2px solid #e5e5e5;
}

.animate-new {
    -webkit-animation: color_change 2s infinite; /* Safari 4.0 - 8.0 */
    animation: color_change 2s infinite;
    margin-top: 4px;
    display: block;
    font-weight: 700;
    border: 1px solid black;
}
@-webkit-keyframes color_change {
    0% {
        background: #e7e75f;
        color: white;
    }
    50% {
        background: white;
        color: black;
    }
    100% {
        background: #e7e75f;
        color: white;
    }
}
@keyframes color_change {
    0% {
        background: #e7e75f;
        color: white;
    }
    50% {
        background: white;
        color: black;
    }
    100% {
        background: #e7e75f;
        color: white;
    }
}
