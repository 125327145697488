// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
//@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

//Custom Css
@import "../css/color.css";
@import "../css/loader.css";
@import "../css/global.css";


