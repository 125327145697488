.bg-red {
    background-color: #f44336 !important;
}

.bg-red-50 {
    background-color: #ffebee !important;
}

.bg-red-100 {
    background-color: #ffcdd2 !important;
}

.bg-red-200 {
    background-color: #ef9a9a !important;
}

.bg-red-300 {
    background-color: #e57373 !important;
}

.bg-red-400 {
    background-color: #ef5350 !important;
}

.bg-red-500 {
    background-color: #f44336 !important;
}

.bg-red-600 {
    background-color: #e53935 !important;
}

.bg-red-700 {
    background-color: #d32f2f !important;
}

.bg-red-800 {
    background-color: #c62828 !important;
}

.bg-red-900 {
    background-color: #b71c1c !important;
}

.bg-red-a100 {
    background-color: #ff8a80 !important;
}

.bg-red-a200 {
    background-color: #ff5252 !important;
}

.bg-red-a400 {
    background-color: #ff1744 !important;
}

.bg-red-a700 {
    background-color: #d50000 !important;
}

.bg-pink {
    background-color: #e91e63 !important;
}

.bg-pink-50 {
    background-color: #fce4ec !important;
}

.bg-pink-100 {
    background-color: #f8bbd0 !important;
}

.bg-pink-200 {
    background-color: #f48fb1 !important;
}

.bg-pink-300 {
    background-color: #f06292 !important;
}

.bg-pink-400 {
    background-color: #ec407a !important;
}

.bg-pink-500 {
    background-color: #e91e63 !important;
}

.bg-pink-600 {
    background-color: #d81b60 !important;
}

.bg-pink-700 {
    background-color: #c2185b !important;
}

.bg-pink-800 {
    background-color: #ad1457 !important;
}

.bg-pink-900 {
    background-color: #880e4f !important;
}

.bg-pink-a100 {
    background-color: #ff80ab !important;
}

.bg-pink-a200 {
    background-color: #ff4081 !important;
}

.bg-pink-a400 {
    background-color: #f50057 !important;
}

.bg-pink-a700 {
    background-color: #c51162 !important;
}

.bg-purple {
    background-color: #9c27b0 !important;
}

.bg-purple-50 {
    background-color: #f3e5f5 !important;
}

.bg-purple-100 {
    background-color: #e1bee7 !important;
}

.bg-purple-200 {
    background-color: #ce93d8 !important;
}

.bg-purple-300 {
    background-color: #ba68c8 !important;
}

.bg-purple-400 {
    background-color: #ab47bc !important;
}

.bg-purple-500 {
    background-color: #9c27b0 !important;
}

.bg-purple-600 {
    background-color: #8e24aa !important;
}

.bg-purple-700 {
    background-color: #7b1fa2 !important;
}

.bg-purple-800 {
    background-color: #6a1b9a !important;
}

.bg-purple-900 {
    background-color: #4a148c !important;
}

.bg-purple-a100 {
    background-color: #ea80fc !important;
}

.bg-purple-a200 {
    background-color: #e040fb !important;
}

.bg-purple-a400 {
    background-color: #d500f9 !important;
}

.bg-purple-a700 {
    background-color: #a0f !important;
}

.bg-deep-purple {
    background-color: #673ab7 !important;
}

.bg-deep-purple-50 {
    background-color: #ede7f6 !important;
}

.bg-deep-purple-100 {
    background-color: #d1c4e9 !important;
}

.bg-deep-purple-200 {
    background-color: #b39ddb !important;
}

.bg-deep-purple-300 {
    background-color: #9575cd !important;
}

.bg-deep-purple-400 {
    background-color: #7e57c2 !important;
}

.bg-deep-purple-500 {
    background-color: #673ab7 !important;
}

.bg-deep-purple-600 {
    background-color: #5e35b1 !important;
}

.bg-deep-purple-700 {
    background-color: #512da8 !important;
}

.bg-deep-purple-800 {
    background-color: #4527a0 !important;
}

.bg-deep-purple-900 {
    background-color: #311b92 !important;
}

.bg-deep-purple-a100 {
    background-color: #b388ff !important;
}

.bg-deep-purple-a200 {
    background-color: #7c4dff !important;
}

.bg-deep-purple-a400 {
    background-color: #651fff !important;
}

.bg-deep-purple-a700 {
    background-color: #6200ea !important;
}

.bg-indigo {
    background-color: #3f51b5 !important;
}

.bg-indigo-50 {
    background-color: #e8eaf6 !important;
}

.bg-indigo-100 {
    background-color: #c5cae9 !important;
}

.bg-indigo-200 {
    background-color: #9fa8da !important;
}

.bg-indigo-300 {
    background-color: #7986cb !important;
}

.bg-indigo-400 {
    background-color: #5c6bc0 !important;
}

.bg-indigo-500 {
    background-color: #3f51b5 !important;
}

.bg-indigo-600 {
    background-color: #3949ab !important;
}

.bg-indigo-700 {
    background-color: #303f9f !important;
}

.bg-indigo-800 {
    background-color: #283593 !important;
}

.bg-indigo-900 {
    background-color: #1a237e !important;
}

.bg-indigo-a100 {
    background-color: #8c9eff !important;
}

.bg-indigo-a200 {
    background-color: #536dfe !important;
}

.bg-indigo-a400 {
    background-color: #3d5afe !important;
}

.bg-indigo-a700 {
    background-color: #304ffe !important;
}

.bg-blue {
    background-color: #2196f3 !important;
}

.bg-blue-50 {
    background-color: #e3f2fd !important;
}

.bg-blue-100 {
    background-color: #bbdefb !important;
}

.bg-blue-200 {
    background-color: #90caf9 !important;
}

.bg-blue-300 {
    background-color: #64b5f6 !important;
}

.bg-blue-400 {
    background-color: #42a5f5 !important;
}

.bg-blue-500 {
    background-color: #2196f3 !important;
}

.bg-blue-600 {
    background-color: #1e88e5 !important;
}

.bg-blue-700 {
    background-color: #1976d2 !important;
}

.bg-blue-800 {
    background-color: #1565c0 !important;
}

.bg-blue-900 {
    background-color: #0d47a1 !important;
}

.bg-blue-a100 {
    background-color: #82b1ff !important;
}

.bg-blue-a200 {
    background-color: #448aff !important;
}

.bg-blue-a400 {
    background-color: #2979ff !important;
}

.bg-blue-a700 {
    background-color: #2962ff !important;
}

.bg-light-blue {
    background-color: #03a9f4 !important;
}

.bg-light-blue-50 {
    background-color: #e1f5fe !important;
}

.bg-light-blue-100 {
    background-color: #b3e5fc !important;
}

.bg-light-blue-200 {
    background-color: #81d4fa !important;
}

.bg-light-blue-300 {
    background-color: #4fc3f7 !important;
}

.bg-light-blue-400 {
    background-color: #29b6f6 !important;
}

.bg-light-blue-500 {
    background-color: #03a9f4 !important;
}

.bg-light-blue-600 {
    background-color: #039be5 !important;
}

.bg-light-blue-700 {
    background-color: #0288d1 !important;
}

.bg-light-blue-800 {
    background-color: #0277bd !important;
}

.bg-light-blue-900 {
    background-color: #01579b !important;
}

.bg-light-blue-a100 {
    background-color: #80d8ff !important;
}

.bg-light-blue-a200 {
    background-color: #40c4ff !important;
}

.bg-light-blue-a400 {
    background-color: #00b0ff !important;
}

.bg-light-blue-a700 {
    background-color: #0091ea !important;
}

.bg-cyan {
    background-color: #00bcd4 !important;
}

.bg-cyan-50 {
    background-color: #e0f7fa !important;
}

.bg-cyan-100 {
    background-color: #b2ebf2 !important;
}

.bg-cyan-200 {
    background-color: #80deea !important;
}

.bg-cyan-300 {
    background-color: #4dd0e1 !important;
}

.bg-cyan-400 {
    background-color: #26c6da !important;
}

.bg-cyan-500 {
    background-color: #00bcd4 !important;
}

.bg-cyan-600 {
    background-color: #00acc1 !important;
}

.bg-cyan-700 {
    background-color: #0097a7 !important;
}

.bg-cyan-800 {
    background-color: #00838f !important;
}

.bg-cyan-900 {
    background-color: #006064 !important;
}

.bg-cyan-a100 {
    background-color: #84ffff !important;
}

.bg-cyan-a200 {
    background-color: #18ffff !important;
}

.bg-cyan-a400 {
    background-color: #00e5ff !important;
}

.bg-cyan-a700 {
    background-color: #00b8d4 !important;
}

.bg-teal {
    background-color: #009688 !important;
}

.bg-teal-50 {
    background-color: #e0f2f1 !important;
}

.bg-teal-100 {
    background-color: #b2dfdb !important;
}

.bg-teal-200 {
    background-color: #80cbc4 !important;
}

.bg-teal-300 {
    background-color: #4db6ac !important;
}

.bg-teal-400 {
    background-color: #26a69a !important;
}

.bg-teal-500 {
    background-color: #009688 !important;
}

.bg-teal-600 {
    background-color: #00897b !important;
}

.bg-teal-700 {
    background-color: #00796b !important;
}

.bg-teal-800 {
    background-color: #00695c !important;
}

.bg-teal-900 {
    background-color: #004d40 !important;
}

.bg-teal-a100 {
    background-color: #a7ffeb !important;
}

.bg-teal-a200 {
    background-color: #64ffda !important;
}

.bg-teal-a400 {
    background-color: #1de9b6 !important;
}

.bg-teal-a700 {
    background-color: #00bfa5 !important;
}

.bg-green {
    background-color: #4caf50 !important;
}

.bg-green-50 {
    background-color: #e8f5e9 !important;
}

.bg-green-100 {
    background-color: #c8e6c9 !important;
}

.bg-green-200 {
    background-color: #a5d6a7 !important;
}

.bg-green-300 {
    background-color: #81c784 !important;
}

.bg-green-400 {
    background-color: #66bb6a !important;
}

.bg-green-500 {
    background-color: #4caf50 !important;
}

.bg-green-600 {
    background-color: #43a047 !important;
}

.bg-green-700 {
    background-color: #388e3c !important;
}

.bg-green-800 {
    background-color: #2e7d32 !important;
}

.bg-green-900 {
    background-color: #1b5e20 !important;
}

.bg-green-a100 {
    background-color: #b9f6ca !important;
}

.bg-green-a200 {
    background-color: #69f0ae !important;
}

.bg-green-a400 {
    background-color: #00e676 !important;
}

.bg-green-a700 {
    background-color: #00c853 !important;
}

.bg-light-green {
    background-color: #8bc34a !important;
}

.bg-light-green-50 {
    background-color: #f1f8e9 !important;
}

.bg-light-green-100 {
    background-color: #dcedc8 !important;
}

.bg-light-green-200 {
    background-color: #c5e1a5 !important;
}

.bg-light-green-300 {
    background-color: #aed581 !important;
}

.bg-light-green-400 {
    background-color: #9ccc65 !important;
}

.bg-light-green-500 {
    background-color: #8bc34a !important;
}

.bg-light-green-600 {
    background-color: #7cb342 !important;
}

.bg-light-green-700 {
    background-color: #689f38 !important;
}

.bg-light-green-800 {
    background-color: #558b2f !important;
}

.bg-light-green-900 {
    background-color: #33691e !important;
}

.bg-light-green-a100 {
    background-color: #ccff90 !important;
}

.bg-light-green-a200 {
    background-color: #b2ff59 !important;
}

.bg-light-green-a400 {
    background-color: #76ff03 !important;
}

.bg-light-green-a700 {
    background-color: #64dd17 !important;
}

.bg-lime {
    background-color: #cddc39 !important;
}

.bg-lime-50 {
    background-color: #f9fbe7 !important;
}

.bg-lime-100 {
    background-color: #f0f4c3 !important;
}

.bg-lime-200 {
    background-color: #e6ee9c !important;
}

.bg-lime-300 {
    background-color: #dce775 !important;
}

.bg-lime-400 {
    background-color: #d4e157 !important;
}

.bg-lime-500 {
    background-color: #cddc39 !important;
}

.bg-lime-600 {
    background-color: #c0ca33 !important;
}

.bg-lime-700 {
    background-color: #afb42b !important;
}

.bg-lime-800 {
    background-color: #9e9d24 !important;
}

.bg-lime-900 {
    background-color: #827717 !important;
}

.bg-lime-a100 {
    background-color: #f4ff81 !important;
}

.bg-lime-a200 {
    background-color: #eeff41 !important;
}

.bg-lime-a400 {
    background-color: #c6ff00 !important;
}

.bg-lime-a700 {
    background-color: #aeea00 !important;
}

.bg-yellow {
    background-color: #ffeb3b !important;
}

.bg-yellow-50 {
    background-color: #fffde7 !important;
}

.bg-yellow-100 {
    background-color: #fff9c4 !important;
}

.bg-yellow-200 {
    background-color: #fff59d !important;
}

.bg-yellow-300 {
    background-color: #fff176 !important;
}

.bg-yellow-400 {
    background-color: #ffee58 !important;
}

.bg-yellow-500 {
    background-color: #ffeb3b !important;
}

.bg-yellow-600 {
    background-color: #fdd835 !important;
}

.bg-yellow-700 {
    background-color: #fbc02d !important;
}

.bg-yellow-800 {
    background-color: #f9a825 !important;
}

.bg-yellow-900 {
    background-color: #f57f17 !important;
}

.bg-yellow-a100 {
    background-color: #ffff8d !important;
}

.bg-yellow-a200 {
    background-color: #ff0 !important;
}

.bg-yellow-a400 {
    background-color: #ffea00 !important;
}

.bg-yellow-a700 {
    background-color: #ffd600 !important;
}

.bg-amber {
    background-color: #ffc107 !important;
}

.bg-amber-50 {
    background-color: #fff8e1 !important;
}

.bg-amber-100 {
    background-color: #ffecb3 !important;
}

.bg-amber-200 {
    background-color: #ffe082 !important;
}

.bg-amber-300 {
    background-color: #ffd54f !important;
}

.bg-amber-400 {
    background-color: #ffca28 !important;
}

.bg-amber-500 {
    background-color: #ffc107 !important;
}

.bg-amber-600 {
    background-color: #ffb300 !important;
}

.bg-amber-700 {
    background-color: #ffa000 !important;
}

.bg-amber-800 {
    background-color: #ff8f00 !important;
}

.bg-amber-900 {
    background-color: #ff6f00 !important;
}

.bg-amber-a100 {
    background-color: #ffe57f !important;
}

.bg-amber-a200 {
    background-color: #ffd740 !important;
}

.bg-amber-a400 {
    background-color: #ffc400 !important;
}

.bg-amber-a700 {
    background-color: #ffab00 !important;
}

.bg-orange {
    background-color: #ff9800 !important;
}

.bg-orange-50 {
    background-color: #fff3e0 !important;
}

.bg-orange-100 {
    background-color: #ffe0b2 !important;
}

.bg-orange-200 {
    background-color: #ffcc80 !important;
}

.bg-orange-300 {
    background-color: #ffb74d !important;
}

.bg-orange-400 {
    background-color: #ffa726 !important;
}

.bg-orange-500 {
    background-color: #ff9800 !important;
}

.bg-orange-600 {
    background-color: #fb8c00 !important;
}

.bg-orange-700 {
    background-color: #f57c00 !important;
}

.bg-orange-800 {
    background-color: #ef6c00 !important;
}

.bg-orange-900 {
    background-color: #e65100 !important;
}

.bg-orange-a100 {
    background-color: #ffd180 !important;
}

.bg-orange-a200 {
    background-color: #ffab40 !important;
}

.bg-orange-a400 {
    background-color: #ff9100 !important;
}

.bg-orange-a700 {
    background-color: #ff6d00 !important;
}

.bg-deep-orange {
    background-color: #ff5722 !important;
}

.bg-deep-orange-50 {
    background-color: #fbe9e7 !important;
}

.bg-deep-orange-100 {
    background-color: #ffccbc !important;
}

.bg-deep-orange-200 {
    background-color: #ffab91 !important;
}

.bg-deep-orange-300 {
    background-color: #ff8a65 !important;
}

.bg-deep-orange-400 {
    background-color: #ff7043 !important;
}

.bg-deep-orange-500 {
    background-color: #ff5722 !important;
}

.bg-deep-orange-600 {
    background-color: #f4511e !important;
}

.bg-deep-orange-700 {
    background-color: #e64a19 !important;
}

.bg-deep-orange-800 {
    background-color: #d84315 !important;
}

.bg-deep-orange-900 {
    background-color: #bf360c !important;
}

.bg-deep-orange-a100 {
    background-color: #ff9e80 !important;
}

.bg-deep-orange-a200 {
    background-color: #ff6e40 !important;
}

.bg-deep-orange-a400 {
    background-color: #ff3d00 !important;
}

.bg-deep-orange-a700 {
    background-color: #dd2c00 !important;
}

.bg-brown {
    background-color: #795548 !important;
}

.bg-brown-50 {
    background-color: #efebe9 !important;
}

.bg-brown-100 {
    background-color: #d7ccc8 !important;
}

.bg-brown-200 {
    background-color: #bcaaa4 !important;
}

.bg-brown-300 {
    background-color: #a1887f !important;
}

.bg-brown-400 {
    background-color: #8d6e63 !important;
}

.bg-brown-500 {
    background-color: #795548 !important;
}

.bg-brown-600 {
    background-color: #6d4c41 !important;
}

.bg-brown-700 {
    background-color: #5d4037 !important;
}

.bg-brown-800 {
    background-color: #4e342e !important;
}

.bg-brown-900 {
    background-color: #3e2723 !important;
}

.bg-grey {
    background-color: #9e9e9e !important;
}

.bg-grey-50 {
    background-color: #fafafa !important;
}

.bg-grey-100 {
    background-color: #f5f5f5 !important;
}

.bg-grey-200 {
    background-color: #eee !important;
}

.bg-grey-300 {
    background-color: #e0e0e0 !important;
}

.bg-grey-400 {
    background-color: #bdbdbd !important;
}

.bg-grey-500 {
    background-color: #9e9e9e !important;
}

.bg-grey-600 {
    background-color: #757575 !important;
}

.bg-grey-700 {
    background-color: #616161 !important;
}

.bg-grey-800 {
    background-color: #424242 !important;
}

.bg-grey-900 {
    background-color: #212121 !important;
}

.bg-blue-grey {
    background-color: #607d8b !important;
}

.bg-blue-grey-50 {
    background-color: #eceff1 !important;
}

.bg-blue-grey-100 {
    background-color: #cfd8dc !important;
}

.bg-blue-grey-200 {
    background-color: #b0bec5 !important;
}

.bg-blue-grey-300 {
    background-color: #90a4ae !important;
}

.bg-blue-grey-400 {
    background-color: #78909c !important;
}

.bg-blue-grey-500 {
    background-color: #607d8b !important;
}

.bg-blue-grey-600 {
    background-color: #546e7a !important;
}

.bg-blue-grey-700 {
    background-color: #455a64 !important;
}

.bg-blue-grey-800 {
    background-color: #37474f !important;
}

.bg-blue-grey-900 {
    background-color: #263238 !important;
}

.bg-black {
    background-color: #000 !important;
}

.bg-white {
    background-color: #fff !important;
}

.fg-red {
    color: #f44336 !important;
}

.fg-red-50 {
    color: #ffebee !important;
}

.fg-red-100 {
    color: #ffcdd2 !important;
}

.fg-red-200 {
    color: #ef9a9a !important;
}

.fg-red-300 {
    color: #e57373 !important;
}

.fg-red-400 {
    color: #ef5350 !important;
}

.fg-red-500 {
    color: #f44336 !important;
}

.fg-red-600 {
    color: #e53935 !important;
}

.fg-red-700 {
    color: #d32f2f !important;
}

.fg-red-800 {
    color: #c62828 !important;
}

.fg-red-900 {
    color: #b71c1c !important;
}

.fg-red-a100 {
    color: #ff8a80 !important;
}

.fg-red-a200 {
    color: #ff5252 !important;
}

.fg-red-a400 {
    color: #ff1744 !important;
}

.fg-red-a700 {
    color: #d50000 !important;
}

.fg-pink {
    color: #e91e63 !important;
}

.fg-pink-50 {
    color: #fce4ec !important;
}

.fg-pink-100 {
    color: #f8bbd0 !important;
}

.fg-pink-200 {
    color: #f48fb1 !important;
}

.fg-pink-300 {
    color: #f06292 !important;
}

.fg-pink-400 {
    color: #ec407a !important;
}

.fg-pink-500 {
    color: #e91e63 !important;
}

.fg-pink-600 {
    color: #d81b60 !important;
}

.fg-pink-700 {
    color: #c2185b !important;
}

.fg-pink-800 {
    color: #ad1457 !important;
}

.fg-pink-900 {
    color: #880e4f !important;
}

.fg-pink-a100 {
    color: #ff80ab !important;
}

.fg-pink-a200 {
    color: #ff4081 !important;
}

.fg-pink-a400 {
    color: #f50057 !important;
}

.fg-pink-a700 {
    color: #c51162 !important;
}

.fg-purple {
    color: #9c27b0 !important;
}

.fg-purple-50 {
    color: #f3e5f5 !important;
}

.fg-purple-100 {
    color: #e1bee7 !important;
}

.fg-purple-200 {
    color: #ce93d8 !important;
}

.fg-purple-300 {
    color: #ba68c8 !important;
}

.fg-purple-400 {
    color: #ab47bc !important;
}

.fg-purple-500 {
    color: #9c27b0 !important;
}

.fg-purple-600 {
    color: #8e24aa !important;
}

.fg-purple-700 {
    color: #7b1fa2 !important;
}

.fg-purple-800 {
    color: #6a1b9a !important;
}

.fg-purple-900 {
    color: #4a148c !important;
}

.fg-purple-a100 {
    color: #ea80fc !important;
}

.fg-purple-a200 {
    color: #e040fb !important;
}

.fg-purple-a400 {
    color: #d500f9 !important;
}

.fg-purple-a700 {
    color: #a0f !important;
}

.fg-deep-purple {
    color: #673ab7 !important;
}

.fg-deep-purple-50 {
    color: #ede7f6 !important;
}

.fg-deep-purple-100 {
    color: #d1c4e9 !important;
}

.fg-deep-purple-200 {
    color: #b39ddb !important;
}

.fg-deep-purple-300 {
    color: #9575cd !important;
}

.fg-deep-purple-400 {
    color: #7e57c2 !important;
}

.fg-deep-purple-500 {
    color: #673ab7 !important;
}

.fg-deep-purple-600 {
    color: #5e35b1 !important;
}

.fg-deep-purple-700 {
    color: #512da8 !important;
}

.fg-deep-purple-800 {
    color: #4527a0 !important;
}

.fg-deep-purple-900 {
    color: #311b92 !important;
}

.fg-deep-purple-a100 {
    color: #b388ff !important;
}

.fg-deep-purple-a200 {
    color: #7c4dff !important;
}

.fg-deep-purple-a400 {
    color: #651fff !important;
}

.fg-deep-purple-a700 {
    color: #6200ea !important;
}

.fg-indigo {
    color: #3f51b5 !important;
}

.fg-indigo-50 {
    color: #e8eaf6 !important;
}

.fg-indigo-100 {
    color: #c5cae9 !important;
}

.fg-indigo-200 {
    color: #9fa8da !important;
}

.fg-indigo-300 {
    color: #7986cb !important;
}

.fg-indigo-400 {
    color: #5c6bc0 !important;
}

.fg-indigo-500 {
    color: #3f51b5 !important;
}

.fg-indigo-600 {
    color: #3949ab !important;
}

.fg-indigo-700 {
    color: #303f9f !important;
}

.fg-indigo-800 {
    color: #283593 !important;
}

.fg-indigo-900 {
    color: #1a237e !important;
}

.fg-indigo-a100 {
    color: #8c9eff !important;
}

.fg-indigo-a200 {
    color: #536dfe !important;
}

.fg-indigo-a400 {
    color: #3d5afe !important;
}

.fg-indigo-a700 {
    color: #304ffe !important;
}

.fg-blue {
    color: #2196f3 !important;
}

.fg-blue-50 {
    color: #e3f2fd !important;
}

.fg-blue-100 {
    color: #bbdefb !important;
}

.fg-blue-200 {
    color: #90caf9 !important;
}

.fg-blue-300 {
    color: #64b5f6 !important;
}

.fg-blue-400 {
    color: #42a5f5 !important;
}

.fg-blue-500 {
    color: #2196f3 !important;
}

.fg-blue-600 {
    color: #1e88e5 !important;
}

.fg-blue-700 {
    color: #1976d2 !important;
}

.fg-blue-800 {
    color: #1565c0 !important;
}

.fg-blue-900 {
    color: #0d47a1 !important;
}

.fg-blue-a100 {
    color: #82b1ff !important;
}

.fg-blue-a200 {
    color: #448aff !important;
}

.fg-blue-a400 {
    color: #2979ff !important;
}

.fg-blue-a700 {
    color: #2962ff !important;
}

.fg-light-blue {
    color: #03a9f4 !important;
}

.fg-light-blue-50 {
    color: #e1f5fe !important;
}

.fg-light-blue-100 {
    color: #b3e5fc !important;
}

.fg-light-blue-200 {
    color: #81d4fa !important;
}

.fg-light-blue-300 {
    color: #4fc3f7 !important;
}

.fg-light-blue-400 {
    color: #29b6f6 !important;
}

.fg-light-blue-500 {
    color: #03a9f4 !important;
}

.fg-light-blue-600 {
    color: #039be5 !important;
}

.fg-light-blue-700 {
    color: #0288d1 !important;
}

.fg-light-blue-800 {
    color: #0277bd !important;
}

.fg-light-blue-900 {
    color: #01579b !important;
}

.fg-light-blue-a100 {
    color: #80d8ff !important;
}

.fg-light-blue-a200 {
    color: #40c4ff !important;
}

.fg-light-blue-a400 {
    color: #00b0ff !important;
}

.fg-light-blue-a700 {
    color: #0091ea !important;
}

.fg-cyan {
    color: #00bcd4 !important;
}

.fg-cyan-50 {
    color: #e0f7fa !important;
}

.fg-cyan-100 {
    color: #b2ebf2 !important;
}

.fg-cyan-200 {
    color: #80deea !important;
}

.fg-cyan-300 {
    color: #4dd0e1 !important;
}

.fg-cyan-400 {
    color: #26c6da !important;
}

.fg-cyan-500 {
    color: #00bcd4 !important;
}

.fg-cyan-600 {
    color: #00acc1 !important;
}

.fg-cyan-700 {
    color: #0097a7 !important;
}

.fg-cyan-800 {
    color: #00838f !important;
}

.fg-cyan-900 {
    color: #006064 !important;
}

.fg-cyan-a100 {
    color: #84ffff !important;
}

.fg-cyan-a200 {
    color: #18ffff !important;
}

.fg-cyan-a400 {
    color: #00e5ff !important;
}

.fg-cyan-a700 {
    color: #00b8d4 !important;
}

.fg-teal {
    color: #009688 !important;
}

.fg-teal-50 {
    color: #e0f2f1 !important;
}

.fg-teal-100 {
    color: #b2dfdb !important;
}

.fg-teal-200 {
    color: #80cbc4 !important;
}

.fg-teal-300 {
    color: #4db6ac !important;
}

.fg-teal-400 {
    color: #26a69a !important;
}

.fg-teal-500 {
    color: #009688 !important;
}

.fg-teal-600 {
    color: #00897b !important;
}

.fg-teal-700 {
    color: #00796b !important;
}

.fg-teal-800 {
    color: #00695c !important;
}

.fg-teal-900 {
    color: #004d40 !important;
}

.fg-teal-a100 {
    color: #a7ffeb !important;
}

.fg-teal-a200 {
    color: #64ffda !important;
}

.fg-teal-a400 {
    color: #1de9b6 !important;
}

.fg-teal-a700 {
    color: #00bfa5 !important;
}

.fg-green {
    color: #4caf50 !important;
}

.fg-green-50 {
    color: #e8f5e9 !important;
}

.fg-green-100 {
    color: #c8e6c9 !important;
}

.fg-green-200 {
    color: #a5d6a7 !important;
}

.fg-green-300 {
    color: #81c784 !important;
}

.fg-green-400 {
    color: #66bb6a !important;
}

.fg-green-500 {
    color: #4caf50 !important;
}

.fg-green-600 {
    color: #43a047 !important;
}

.fg-green-700 {
    color: #388e3c !important;
}

.fg-green-800 {
    color: #2e7d32 !important;
}

.fg-green-900 {
    color: #1b5e20 !important;
}

.fg-green-a100 {
    color: #b9f6ca !important;
}

.fg-green-a200 {
    color: #69f0ae !important;
}

.fg-green-a400 {
    color: #00e676 !important;
}

.fg-green-a700 {
    color: #00c853 !important;
}

.fg-light-green {
    color: #8bc34a !important;
}

.fg-light-green-50 {
    color: #f1f8e9 !important;
}

.fg-light-green-100 {
    color: #dcedc8 !important;
}

.fg-light-green-200 {
    color: #c5e1a5 !important;
}

.fg-light-green-300 {
    color: #aed581 !important;
}

.fg-light-green-400 {
    color: #9ccc65 !important;
}

.fg-light-green-500 {
    color: #8bc34a !important;
}

.fg-light-green-600 {
    color: #7cb342 !important;
}

.fg-light-green-700 {
    color: #689f38 !important;
}

.fg-light-green-800 {
    color: #558b2f !important;
}

.fg-light-green-900 {
    color: #33691e !important;
}

.fg-light-green-a100 {
    color: #ccff90 !important;
}

.fg-light-green-a200 {
    color: #b2ff59 !important;
}

.fg-light-green-a400 {
    color: #76ff03 !important;
}

.fg-light-green-a700 {
    color: #64dd17 !important;
}

.fg-lime {
    color: #cddc39 !important;
}

.fg-lime-50 {
    color: #f9fbe7 !important;
}

.fg-lime-100 {
    color: #f0f4c3 !important;
}

.fg-lime-200 {
    color: #e6ee9c !important;
}

.fg-lime-300 {
    color: #dce775 !important;
}

.fg-lime-400 {
    color: #d4e157 !important;
}

.fg-lime-500 {
    color: #cddc39 !important;
}

.fg-lime-600 {
    color: #c0ca33 !important;
}

.fg-lime-700 {
    color: #afb42b !important;
}

.fg-lime-800 {
    color: #9e9d24 !important;
}

.fg-lime-900 {
    color: #827717 !important;
}

.fg-lime-a100 {
    color: #f4ff81 !important;
}

.fg-lime-a200 {
    color: #eeff41 !important;
}

.fg-lime-a400 {
    color: #c6ff00 !important;
}

.fg-lime-a700 {
    color: #aeea00 !important;
}

.fg-yellow {
    color: #ffeb3b !important;
}

.fg-yellow-50 {
    color: #fffde7 !important;
}

.fg-yellow-100 {
    color: #fff9c4 !important;
}

.fg-yellow-200 {
    color: #fff59d !important;
}

.fg-yellow-300 {
    color: #fff176 !important;
}

.fg-yellow-400 {
    color: #ffee58 !important;
}

.fg-yellow-500 {
    color: #ffeb3b !important;
}

.fg-yellow-600 {
    color: #fdd835 !important;
}

.fg-yellow-700 {
    color: #fbc02d !important;
}

.fg-yellow-800 {
    color: #f9a825 !important;
}

.fg-yellow-900 {
    color: #f57f17 !important;
}

.fg-yellow-a100 {
    color: #ffff8d !important;
}

.fg-yellow-a200 {
    color: #ff0 !important;
}

.fg-yellow-a400 {
    color: #ffea00 !important;
}

.fg-yellow-a700 {
    color: #ffd600 !important;
}

.fg-amber {
    color: #ffc107 !important;
}

.fg-amber-50 {
    color: #fff8e1 !important;
}

.fg-amber-100 {
    color: #ffecb3 !important;
}

.fg-amber-200 {
    color: #ffe082 !important;
}

.fg-amber-300 {
    color: #ffd54f !important;
}

.fg-amber-400 {
    color: #ffca28 !important;
}

.fg-amber-500 {
    color: #ffc107 !important;
}

.fg-amber-600 {
    color: #ffb300 !important;
}

.fg-amber-700 {
    color: #ffa000 !important;
}

.fg-amber-800 {
    color: #ff8f00 !important;
}

.fg-amber-900 {
    color: #ff6f00 !important;
}

.fg-amber-a100 {
    color: #ffe57f !important;
}

.fg-amber-a200 {
    color: #ffd740 !important;
}

.fg-amber-a400 {
    color: #ffc400 !important;
}

.fg-amber-a700 {
    color: #ffab00 !important;
}

.fg-orange {
    color: #ff9800 !important;
}

.fg-orange-50 {
    color: #fff3e0 !important;
}

.fg-orange-100 {
    color: #ffe0b2 !important;
}

.fg-orange-200 {
    color: #ffcc80 !important;
}

.fg-orange-300 {
    color: #ffb74d !important;
}

.fg-orange-400 {
    color: #ffa726 !important;
}

.fg-orange-500 {
    color: #ff9800 !important;
}

.fg-orange-600 {
    color: #fb8c00 !important;
}

.fg-orange-700 {
    color: #f57c00 !important;
}

.fg-orange-800 {
    color: #ef6c00 !important;
}

.fg-orange-900 {
    color: #e65100 !important;
}

.fg-orange-a100 {
    color: #ffd180 !important;
}

.fg-orange-a200 {
    color: #ffab40 !important;
}

.fg-orange-a400 {
    color: #ff9100 !important;
}

.fg-orange-a700 {
    color: #ff6d00 !important;
}

.fg-deep-orange {
    color: #ff5722 !important;
}

.fg-deep-orange-50 {
    color: #fbe9e7 !important;
}

.fg-deep-orange-100 {
    color: #ffccbc !important;
}

.fg-deep-orange-200 {
    color: #ffab91 !important;
}

.fg-deep-orange-300 {
    color: #ff8a65 !important;
}

.fg-deep-orange-400 {
    color: #ff7043 !important;
}

.fg-deep-orange-500 {
    color: #ff5722 !important;
}

.fg-deep-orange-600 {
    color: #f4511e !important;
}

.fg-deep-orange-700 {
    color: #e64a19 !important;
}

.fg-deep-orange-800 {
    color: #d84315 !important;
}

.fg-deep-orange-900 {
    color: #bf360c !important;
}

.fg-deep-orange-a100 {
    color: #ff9e80 !important;
}

.fg-deep-orange-a200 {
    color: #ff6e40 !important;
}

.fg-deep-orange-a400 {
    color: #ff3d00 !important;
}

.fg-deep-orange-a700 {
    color: #dd2c00 !important;
}

.fg-brown {
    color: #795548 !important;
}

.fg-brown-50 {
    color: #efebe9 !important;
}

.fg-brown-100 {
    color: #d7ccc8 !important;
}

.fg-brown-200 {
    color: #bcaaa4 !important;
}

.fg-brown-300 {
    color: #a1887f !important;
}

.fg-brown-400 {
    color: #8d6e63 !important;
}

.fg-brown-500 {
    color: #795548 !important;
}

.fg-brown-600 {
    color: #6d4c41 !important;
}

.fg-brown-700 {
    color: #5d4037 !important;
}

.fg-brown-800 {
    color: #4e342e !important;
}

.fg-brown-900 {
    color: #3e2723 !important;
}

.fg-grey {
    color: #9e9e9e !important;
}

.fg-grey-50 {
    color: #fafafa !important;
}

.fg-grey-100 {
    color: #f5f5f5 !important;
}

.fg-grey-200 {
    color: #eee !important;
}

.fg-grey-300 {
    color: #e0e0e0 !important;
}

.fg-grey-400 {
    color: #bdbdbd !important;
}

.fg-grey-500 {
    color: #9e9e9e !important;
}

.fg-grey-600 {
    color: #757575 !important;
}

.fg-grey-700 {
    color: #616161 !important;
}

.fg-grey-800 {
    color: #424242 !important;
}

.fg-grey-900 {
    color: #212121 !important;
}

.fg-blue-grey {
    color: #607d8b !important;
}

.fg-blue-grey-50 {
    color: #eceff1 !important;
}

.fg-blue-grey-100 {
    color: #cfd8dc !important;
}

.fg-blue-grey-200 {
    color: #b0bec5 !important;
}

.fg-blue-grey-300 {
    color: #90a4ae !important;
}

.fg-blue-grey-400 {
    color: #78909c !important;
}

.fg-blue-grey-500 {
    color: #607d8b !important;
}

.fg-blue-grey-600 {
    color: #546e7a !important;
}

.fg-blue-grey-700 {
    color: #455a64 !important;
}

.fg-blue-grey-800 {
    color: #37474f !important;
}

.fg-blue-grey-900 {
    color: #263238 !important;
}

.fg-black {
    color: #000 !important;
}

.fg-white {
    color: #fff;
}
